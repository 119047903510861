import * as React from "react"
import Layout from "../components/layout"
import '../components/index.css'
import { TextField } from "@mui/material";
import { useState } from "react";
import { Link } from "gatsby"
import '../components/signup.css'


const pageStyles = {
    color: "#232129",
    fontFamily: "Roboto, sans-serif, serif",
}

const AboutYou = ({ callback }) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [creationSuccess, setCreationSuccess] = useState(null)
    const [error, setError] = useState("")

    return <>
        <h2 style={{ textAlign: "center", marginBottom: "0rem" }}>Create account</h2>
        <div style={{ textAlign: "center", marginBottom: "1rem" }}>Already have an account? <Link to="/login">Login</Link></div>
        <TextField fullWidth label="Email" defaultValue={email} onChange={(e) => setEmail(e.target.value)} sx={{ marginTop: "1rem" }} />
        <TextField type="password" fullWidth label="Password" onChange={(e) => setPassword(e.target.value)} sx={{ marginTop: "1rem" }} />
        <div
            className="clickable"
            onClick={() => fetch('https://api.thefoodapi.com/signup', {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            }).then(resp => {
                if (resp.status === 200) {
                    setCreationSuccess(true)
                    callback(email)
                } else {
                    setError("New account could not be created.")
                    setCreationSuccess(false)
                }
            }
            )}
            style={{
                padding: "1rem",
                background: "#007788",
                textAlign: "center",
                borderRadius: "4px",
                color: "white",
                marginTop: "1rem",
                cursor: "pointer"
            }}><b>Sign up</b></div>
        <div style={{
            margin: "1rem",
            textAlign: "center"
        }}>Or</div>
        <div className="clickable" style={{
            display: "flex",
            border: "1px solid rgb(200, 200, 200)",
            borderRadius: "4px",
            padding: "12px",
            justifyContent: "center",
            cursor: "pointer"
        }}
            onClick={() => window.location.href = `https://api.thefoodapi.com/auth/google`}
        >
            <div style={{
                backgroundImage: "url(/static/icons/google-icon.png)",
                width: 24,
                height: 24,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                marginRight: "12px"
            }}></div>
            <div>Sign up with Google</div>
        </div>
        {error && <div style={{ color: "red" }}>{error}</div>}
    </>
}

const Success = ({ email }) => {

    return <>
        <h2 style={{ textAlign: "center", marginBottom: "0rem" }}>Thank you for signing up!</h2>
        <div style={{ marginTop: "4rem", textAlign: "center" }}>An email was sent to <b>{email}</b> for verification.</div>
        {email.endsWith("gmail.com") && <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem"
            }}>Go to
                <a href="https://gmail.com" target="_blank" rel="nofollow" className="clickable" style={{
                    margin: "1rem",
                    padding: "0.5rem",
                    boxShadow: "0px 0px 2px 0px grey",
                    borderRadius: "4px",
                }}>
                    <div style={{
                        width: "7rem",
                        height: "2rem",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundImage: "url('/static/icons/logo_gmail.png'"
                    }}></div>
                </a>
            </div>
        </div>}
    </>
}

const IndexPage = ({ location }) => {
    const [email, setEmail] = React.useState(null)

    React.useEffect(() => {
        fetch('https://api.thefoodapi.com/account/info', {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include"
        }).then(resp => {
            if (resp.status === 200) {
                window.location = '/account'
            }
        })
    }, [])

    return (
        <Layout location={location}>
            <div style={pageStyles}>
                <div style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <div style={{
                        width: "27rem",
                        maxWidth: "95%",
                        background: "white",
                        borderRadius: "8px",
                        padding: "2rem 1rem",
                        marginTop: "5rem"
                    }}>
                        {email != null ? <Success email={email} /> : <AboutYou callback={setEmail} />}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage

export const Head = () => <title>Register Page</title>
